<template>
  <div id="app">
    <WebHeader/>
    <Home/>
    <Foot/>
  </div>
</template>

<script>
import SiteHeader from './components/IndexHeader'
import Home from './components/WebHome'
import Foot from './components/IndexFoot'

export default {
  name: 'App',
  components: {
    WebHeader: SiteHeader,
    Home,
    Foot
  }
}
</script>

<style>
html,body,h1,h2,h3,h4,h5,h6,ul,ol,li,p,blockquote {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
