<template>
<nav>
  <ul>
    <li class="menu"><a href="#">首页</a></li>
    <li class="menu"><a href="#">产品</a></li>
    <li class="menu"><a href="#">我们</a></li>
  </ul>
</nav>
</template>

<script>
export default {
  name: "TopMenu"
}
</script>

<style scoped>
nav{
  margin-top: 8px;
  width: 50%;
  float: right;
  display: inline-block;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 400px;
  display: block;
  height: 48px;
}
li {
  align-items: center;
  float: left;
  height: 48px;
  line-height: 48px;
  width: 64px;
  margin: 0 8px;
  padding: 0 16px;
  letter-spacing: 3px;
}
li a{
  font-size: 21px;
  color: black;
  text-decoration: none;
}
li:hover{
  background-color: #ffcc00;
  border-radius: 10px;
}
</style>
