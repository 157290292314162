<template>
  <div class="main">
    <h1>{{ msg }}</h1>
    <h2>Once you start replacing real human contact with cyber-interaction,</h2>
    <h2>coming back out of the cave can be quite difficult.</h2>
    <div class="tip">
      <img src="../assets/weixin.png"/>
      <span>Wechat</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebHome',
  data () {
    return {
      msg: 'What is the cyber interaction?'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
.main{
  height: 1000px;
}
.tip{
  width: 50px;
  height: 50px;
  text-align: center;
  position: fixed;
  cursor: pointer;
  color: #333;
  display: block;
  line-height: 1.8;
  border: 1px solid #DDD;
  right: 30px;
  bottom: 120px;
  display: inline-block;
  z-index: 1;
}
.tip img {
  width: 24px;
  height: 24px;
  display: block;
  margin: 5px auto 0px;
}
.tip span {
  display: block;
  font-size: 12px;
}
</style>
