<template>
  <div class="foot">
    <p class="copy_right">
      © 2023. All Rights Reserved
    </p>
    <div class="beian">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005950"><img src="../assets/beian.png" style="float:left;"/><p>京公网安备 11011302005950号</p></a>
      <a href="https://beian.miit.gov.cn/" target="_blank"><p>京ICP备2023024506号-1</p></a>
    </div>
  </div>
</template>

<style scoped>
.foot{
  font-size: 12px;
  text-align: center;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 240px;
  background-color: #ffcc00;
  position: relative;
}

.copy_right{
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 8px 0;
}

.beian{
  position: absolute;
  bottom: 0;
  width:100%;
  margin:0 auto;
}

.beian a{
  display:inline-block;
  text-decoration:none;
  height:20px;
  line-height:20px;
}

.beian p{
  float:left;
  height:20px;
  line-height:20px;
  margin: 0px 0px 0px 5px;
  color:#939393;
}
</style>
