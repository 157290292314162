<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/logo.png">
    </div>
    <div class="title title_cn">科信互动</div>
    <div class="title title_en">Cyber-Interaction</div>
    <TopMenu/>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";
export default {
  components: {TopMenu}
}
</script>

<style scoped>
  .header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #ffcc00;
    height: 60px;
    z-index: 1;
    background-color: #DDD;
  }
  .title{
    position: absolute;
    left: 185px;
  }
  .title_cn{
    top: 5px;
    letter-spacing: 1.1em;
  }
  .title_en{
    top: 30px;
  }
  .logo{
    position: absolute;
    left: 120px;
  }
  .logo img{
    width: 60px;
    height: 60px;
  }
</style>
